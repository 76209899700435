<template>
  <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">            
            <div class="content-body">
                <div class="bg-image"> 
                    <div class="row justify-content-center">
                        <div class="col-11 ">            
                            <h2 class="h2-heading">Income Tax Return Filing</h2>    
                            <p class="p-text">Vakilgiri Legals has made Online Tax Filing super easy! Take the first step towards filing your income tax return. Select all the sources of your income (to accurately e-file) and click on Continue to experience Vakilgiri's customized &amp; the easiest e-filing process.</p>
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-top: -80px;">
                     <div class="col-12 col-md-6 col-lg-6" style="padding-right: 5px;">
                                <div class="card">
                                    <div class="card-body">
                                        <h3>Step-6 : Bank Records </h3>
                                        <div class="progress progress-bar-danger">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="100" aria-valuemax="100" style="width: 100%">100%</div>
                                        </div>
                                        <br>
                                        <input class='input-file' id="input_file" @change="uploadStatement" type="file" accept="image/*" required />
                                        <div class="row bnk-acc-style" v-for="(bankrecord, index) in bankrecords" :key="index">
                                            <div class="row">
                                                <div class="col-xl-6 col-6 col-md-6">                                                
                                                    <div><b>Bank - </b>{{bankrecord.bankName}}</div>
                                                    <div><b>A/C No. - </b>{{bankrecord.accountNo}}</div>
                                                    <div><b>IFSC - </b>{{bankrecord.ifsc}}</div>
                                                </div>
                                                <div class="col-xl-6 col-6 col-md-6">
                                                    <div class="mb-1">  

                                                        file upload                                                 
                                                        <button class='btn-sm btn  input_btn text-white' style="background-color:#f35000;padding : 5px 5px 5px 5px" @click="openBrowseImag(bankrecord)"><i class="fa fa-upload" aria-hidden="true"></i></button>                                                  
                                                        <button class='btn-sm btn  input_btn text-white' @click="deleteBankAccount(bankrecord)" style="background-color:#f21300;padding : 5px 5px 5px 5px;"><font-awesome-icon icon="trash" /></button>
                                                        
                                                    </div>
                                                </div>
                                                <br>
                                                <hr>
                                                <br>
                                            </div>
                                            <div class="row" v-if="bankrecord.bank_statement.length > 0">
                                                <div class="col-xl-3 col-md-3 col-12" v-for="(bankstmt,index) in bankrecord.bank_statement" :key="index">
                                                    <div class="row">
                                                        <div class="col-12" style="height:80px;cursor: zoom-in">
                                                            <img @click="viewImage(bankstmt)" style="width:100%;"
                                                                class="img-responsive"
                                                                :src="this.$store.state.imgUrl + '/service/itr/' + bankstmt.client_service_id + '/thumbs/' + bankstmt.bankStatement"
                                                                alt="User profile picture"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 d-grid gap-2">
                                                            <button @click="viewImage(bankstmt)"  class="btn btn-sm text-white" style="background-color:#00364f;">  <font-awesome-icon icon="eye" />         </button>
                                                        </div>
                                                        <div class="col-6 d-grid gap-2">
                                                                <button class='btn-sm btn  input_btn text-white' @click="deleteStatement(bankstmt)" style="background-color:#f21300;padding : 5px 5px 5px 5px;"><font-awesome-icon icon="trash" /></button>
                                                            <!-- <button  class="btn btn-danger btn-sm">Delete</button> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><br>
                                        </div>
                                        
                                        <br>
                                        <div class="row">
                                            <!-- <span>
                                                <button class="fa fa-minus-circle btn-danger" @click="removeAccount(ac)" v-show="ac || ( !ac && form.bank_informations.length > 1)">Remove</button>
                                                <button class="fa fa-plus-circle btn-success" @click="addAccount(ac)" v-show="ac == form.bank_informations.length-1">Add More Bank Accounts</button>
                                            </span> -->
                                            <div class="col-xl-6 col-md-6 col-12" style="padding:5px 5px 5px 5px;">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" v-model="form.bankName" placeholder="Bank Name*" />
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12" style="padding:5px 5px 5px 5px;">
                                              <div class="form-group">
                                                    <input type="text" class="form-control" v-model="form.ifsc" placeholder="IFSC Code*" />
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-md-12 col-12" style="padding:5px 5px 5px 5px;">
                                              <div class="form-group">
                                                    <input type="text" class="form-control" v-model="form.accountNo" placeholder="Account No*" />
                                                </div>
                                            </div>
                                            <div class="d-grid col-4" style="padding:5px 5px 5px 5px;">
                                                <button type="button" @click="saveinfo" class="btn btn-sm text-white" style="background-color:green"><i class="fa fa-check" aria-hidden="true"></i> Save </button>
                                            </div>
                                            
                                        
                                            <!-- <div class="col-xl-8 align-self-center col-md-8 col-12" v-for="(input,k) in form.bank_statements" :key="k">
                                                <span>
                                                    <i class="fa fa-minus-circle btn-danger" @click="remove(k)" v-show="k || ( !k && form.bank_statements.length > 1)">Remove</i>
                                                    <i class="fa fa-plus-circle btn-success" @click="add(k)" v-show="k == form.bank_statements.length-1">Add</i>
                                                </span>
                                                <div class="mb-1">
                                                    <label for="customFile2" class="form-label">Bank Statement Image</label>
                                                    <input class="form-control" type="file" id="customFile2" required />
                                                </div>
                                            
                                                <div class="mb-1">
                                                    <label for="customFile2" class="form-label">Start Date</label>
                                                    <input class="form-control" type="date" v-model="input.startDate" />
                                                </div>
                                            
                                                <div class="mb-1">
                                                    <label for="customFile2" class="form-label">End Date</label>
                                                    <input class="form-control" type="date" v-model="input.endDate" />
                                                </div>
                                            </div> -->
                                            <br> 
                                        </div>
                                        <br>
                                        <h3> Previous Year Financial Statement </h3>
                                        <input class='input-file' id="input_financial_file" @change="uploadFinancialStatement" type="file" accept="image/*" required />
                                        <div class="row bnk-acc-style">
                                            <div class="row">
                                                
                                                <div class="col-xl-12 col-12 col-md-6">
                                                    <div class="mb-1">  
                                                        file upload                                                 
                                                        <button class='btn-sm btn  input_btn text-white' style="background-color:#f35000;padding : 5px 5px 5px 5px" @click="openBrowseFinancialImag"><i class="fa fa-upload" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                                <br>
                                                <hr>
                                                <br>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-md-4 col-12">
                                                    <div class="row">
                                                        <div class="col-12" style="height:80px;cursor: zoom-in">
                                                            <img @click="viewFinanceImage(itrfilling)" style="width:100%;"
                                                                class="img-responsive"
                                                                :src="this.$store.state.imgUrl + '/service/itr/' + itrfilling.client_service_id + '/thumbs/' + itrfilling.lastYearFinancialStatementFile"
                                                                alt="User profile picture"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <button @click="viewFinanceImage(itrfilling)" class="btn btn-sm text-white" style="background-color:#00364f;padding:5px 5px 5px 5px;"> <font-awesome-icon icon="eye" /></button>
                                                        </div>
                                                        <div class="col-6">
                                                            <button @click="deleteFinanceStatement(itrfilling)" class="btn btn-sm text-white" style="background-color:#f21300;padding:5px 5px 5px 5px;"><font-awesome-icon icon="trash" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><br>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 text-start">
                                                <button type="button" @click="backtoclientdocument" class="btn btn-sm text-white" style="background-color:#00364f;width:70%;"> <i class="fa fa-arrow-left"></i>  Back</button>
                                            </div>
                                            <div class="col-6 text-end">
                                                <button type="button" @click="thankyou" class="btn btn-sm text-white" style="background-color:#f21300;width:70%;"> Save & Next <i class="fa fa-arrow-right"></i>                         </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12  col-md-6  col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="heading">FAQ's</h2>
                                                <div class="accordion accordion-margin accordion-border" id="accordionMargin">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginOne">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionMarginOne" aria-expanded="false" aria-controls="accordionMarginOne">
                                                            <i data-feather='plus' class="accordian-plus"></i> What is Salary Income?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginOne" class="accordion-collapse collapse" aria-labelledby="headingMarginOne" data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll
                                                                bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy
                                                                caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut
                                                                marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginTwo">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionMarginTwo" aria-expanded="false" aria-controls="accordionMarginTwo">
                                                                <i data-feather='plus' class="accordian-plus"></i> What is Capital Gain?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginTwo" class="accordion-collapse collapse" aria-labelledby="headingMarginTwo" data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels
                                                                liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o
                                                                gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginThree">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionMarginThree" aria-expanded="false" aria-controls="accordionMarginThree">
                                                                <i data-feather='plus' class="accordian-plus"></i> What is House Property Income?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginThree" class="accordion-collapse collapse" aria-labelledby="headingMarginThree" data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans
                                                                soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet
                                                                muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit
                                                                pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginFour">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionMarginFour" aria-expanded="false" aria-controls="accordionMarginFour">
                                                                <i data-feather='plus' class="accordian-plus"></i> What is Business Income?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginFour" class="accordion-collapse collapse" aria-labelledby="headingMarginFour" data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart
                                                                croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping
                                                                jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie
                                                                wafer tootsie roll. Tootsie roll sweet cupcake.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginFive">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionMarginFive" aria-expanded="false" aria-controls="accordionMarginFive">
                                                                <i data-feather='plus' class="accordian-plus"></i> What are Other Sources of Income?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginFive" class="accordion-collapse collapse" aria-labelledby="headingMarginFive" data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart
                                                                croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping
                                                                jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie
                                                                wafer tootsie roll. Tootsie roll sweet cupcake.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>

                <div class="modal fade" id="showModalCenter" tabindex="-1" aria-labelledby="showModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="showModalCenterTitle">View Document</h5>
                                <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                            </div>
                            <div class="modal-body">
                                <img style="width:100%;"
                                    class="img-responsive"
                                    :src="this.$store.state.imgUrl + '/service/itr/' + this.bankstmt.client_service_id + '/' + this.bankstmt.bankStatement"
                                    alt="User profile picture"
                                />
                            </div>
                            <div class="modal-footer">
                                <button type="button" data-bs-dismiss="modal" class="btn btn-danger"><i data-feather='arrow-left'></i> CLOSE</button>
                                
                                <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Accept</button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="showFinanceModalCenter" tabindex="-1" aria-labelledby="showFinanceModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="showFinanceModalCenterTitle">View Document</h5>
                                <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                            </div>
                            <div class="modal-body">
                                <img style="width:100%;"
                                    class="img-responsive"
                                    :src="this.$store.state.imgUrl + '/service/itr/' + this.itrfilling.client_service_id + '/' + this.itrfilling.lastYearFinancialStatementFile"
                                    alt="User profile picture"
                                />
                            </div>
                            <div class="modal-footer">
                                <button type="button" data-bs-dismiss="modal" class="btn btn-danger"><i data-feather='arrow-left'></i> CLOSE</button>
                                
                                <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Accept</button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <button class="btn btn-danger">VIEW ALL SERVICES <i data-feather='arrow-right-circle'></i></button> -->

            </div>
        </div>
    </div>
    <!-- END: Content-->
</template>

<script>
import jQuery from "jquery";
let $ = jQuery
import Form from 'vform'
export default {
  name: 'partnership6component',
  data() {
    return {
        // termCondition: 'false1'
        bankrecords: [],
        bnkAcc: {},
        bankstmt: {},
        itrfilling: {},
        form: new Form({
            client_service_id: '',
            service_id: '',
            client_id: '',
            bankName: '',
            accountNo: '',
            ifsc: '',
            remark: '',
            passbookPhoto: '',
            bank_statements: [
                {
                    bankStatement: '',
                    startDate: '',
                    endDate: ''
                }
            ],
        }),
    }
  },
  methods: {
    add () {
      this.form.bank_statements.push({bankStatement: '', startDate: '', endDate: ''})
    },
    remove (index) {
      this.form.bank_statements.splice(index, 1)
    },
    viewImage(bankstmt) {
        this.bankstmt = {}
        this.bankstmt = bankstmt
        $('#showModalCenter').modal('show')
    },
    viewFinanceImage(itrfilling) {
        this.itrfilling = {}
        this.itrfilling = itrfilling
        $('#showFinanceModalCenter').modal('show')
    },
    backtoclientdocument () {
        this.$router.push({name: 'Itr5', params: { id: this.$route.params.id}})
    },
    // addAccount () {
    //   this.form.bank_informations.push({bankName: '', ifsc: '', accountNo: ''})
    // },
    // removeAccount (index) {
    //   this.form.bank_informations.splice(index, 1)
    // },
    thankyou () {
        // alert('thank you Sir ')
        this.$router.push({name: 'ThankYou'})
    },
    saveinfo() {
        this.$axios
            .post('retailer/bankaccount',this.form, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
            .then(res => {
                console.log(res.data)
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'The Record uploaded successfully.',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.loadData()
            
            })
    },
    openBrowseImag(bnkAcc){
        console.log(bnkAcc)
        this.bnkAcc = bnkAcc
        document.getElementById('input_file').click()
    },
    openBrowseFinancialImag(){
        document.getElementById('input_financial_file').click()
    },
    uploadStatement(e) {
        let formData = new FormData();
        // console.log(e)
        formData.append('bank_account_id', this.bnkAcc.id)
        formData.append('client_service_id', this.bnkAcc.client_service_id)
        formData.append('client_id', this.bnkAcc.client_id)
        formData.append('service_id', this.bnkAcc.service_id)
        formData.append('bankStatement', e.target.files['0']);

        this.$axios
            .post(`retailer/bankstatement`,formData, {headers: { Authorization: 'Bearer ' + localStorage.accessToken, 'content-type': 'multipart/form-data' }})
            .then(res => {
                console.log(res.data)
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'The image was uploaded successfully.',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.loadData()
            })
    },
    uploadFinancialStatement(e) {
        let formData = new FormData();
        formData.append('lastYearFinancialStatementFile', e.target.files['0']);

        this.$axios
            .post(`retailer/itrfilling/previousfinancialstatement/${this.itrfilling.id}`,formData, {headers: { Authorization: 'Bearer ' + localStorage.accessToken, 'content-type': 'multipart/form-data' }})
            .then(res => {
                console.log(res.data)
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'The document was uploaded successfully.',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.loadData()
            })
    },
    loadData() {
        this.$axios
        .get(`retailer/clientservice/${this.$route.params.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
        .then(res => {
            // console.log(res.data.data)
            this.form.client_service_id = res.data.data.id
            this.form.client_id = res.data.data.client_id
            this.form.service_id = res.data.data.service_id
            this.$axios
            .get(`retailer/bankaccount?client_service_id=${this.$route.params.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
            .then(res => {
                this.bankrecords = res.data.data.data
            })
            this.$axios
            .get(`retailer/itrfilling?client_service_id=${this.$route.params.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
            .then(res => {
                this.itrfilling = res.data.data.data[0]
            })
        })
    },
    deleteStatement (stmt) {
        this.$swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.$axios
                    .delete(`retailer/bankstatement/${stmt.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
                    .then(res => {
                        console.log(res.data)
                        this.$swal.fire('Deleted!', 'Your file has been deleted.', 'success')
                        this.loadData()
                    })
            }
        })
    },
    deleteFinanceStatement (stmt) {
        this.$swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.$axios
                    .delete(`retailer/itrfilling/${stmt.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
                    .then(res => {
                        console.log(res.data)
                        this.$swal.fire('Deleted!', 'Your file has been deleted.', 'success')
                        this.loadData()
                    })
            }
        })
    },
    deleteBankAccount(bnkAcc) {
        this.$swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.$axios
                    .delete(`retailer/bankaccount/${bnkAcc.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
                    .then(res => {
                        console.log(res.data)
                        this.$swal.fire('Deleted!', 'Your Record has been deleted successfully.', 'success')
                        this.loadData()
                    })
            }
        })
    },
  },
  created() {
    if(this.$route.meta.redirectIfLoggedIn == 'true' && localStorage.getItem('accessToken') == null){
      this.$router.push({name: 'Login'})
    } else {
        this.loadData()
    }
  },
}
</script>

<style scoped>
.accordian-plus {
    width: 20px;
    height: 20px;
    stroke: red;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}
.bg-image {
    background-color: #fff;
    background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 0 120px;
    /* text-align: center; */
    color:white;
    /* background-size: 100% 100%;
    width: 100%; */
}
.h2-heading {
    color: white;
    font-family: poppins,Sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}
@media (max-width: 767px){
    .h2-heading {
        font-size: 22px;
    }
}
.heading {
    color: #00364f;
    font-family: poppins,Sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 20px 0;
}







.input-file{
  display:none;  
}
 .bnk-acc-style {
    padding: 10px;
    margin-top: 0.71rem;
    margin-bottom: 0.71rem;
    box-shadow: 0 2px 15px 0 rgb(26 100 163 / 66%) !important;
    border-radius: 0.357rem;
    border-bottom: 0 solid transparent !important;
 }
</style>